@import '../../styles/customMediaQueries.css';

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  /* min-height: 500px;
  height: 55vh;
  max-height: 700px; */
  padding: 0;

  /* @media (--viewportMedium) {
    min-height: 750px;
    height: 70vh;
    max-height: none;
  } */

  /* @media (--viewportLarge) {
    max-height: 800px;
    min-height: 650px;
    height: calc(70vh - var(--topbarHeightDesktop));
  } */
}

.hero {
  flex-grow: 1;
  justify-content: center;
  padding: 0;

  @media (--viewportMedium) {
    padding: 60px 0 80px;
  }
}

.mobileHeroImageSection {
  display: block;
  width: 100%;

  @media (--viewportMedium) {
    display: none;
  }
}

.mobileHeroImage {
  width: 100%;
  height: auto;
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: white;
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1352px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    padding: 0;
  }
}

.sectionBecomeMentor {
  composes: section;
  background-color: #F8F8F8;

  @media (--viewportMedium) {
    background-image: url(../../assets/become-mentor-background.png);
    background-position: bottom right;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}

.sectionContentBecomeMentor {
  composes: sectionContent;
  margin-top: 3vh;

  background-color: rgba( 255, 255, 255, 0 );
}

.sectionRoadmap {
  composes: section;
  background-color: white;

  @media (--viewportMedium) {
    background-image: url(../../assets/roadmap-background.png);
    background-position: top right;
  }
}

.sectionContentRoadmap {
  composes: sectionContent;
  margin-top: 3vh;

  background-color: rgba( 255, 255, 255, 0 );
}

.sectionLearn {
  composes: section;
  position: relative;
  z-index: 100;
  background-color: rgba( 255, 255, 255, 0 )!important;
  margin-bottom: -100px;

  @media (--viewportMedium) {
    background-color: rgba( 255, 255, 255, 0 )!important;
    margin-bottom: -200px;
  }

  @media (--viewportLarge) {
    margin-bottom: -260px;
  }
}

.sectionContentLearn {
  margin: var(--LandingPage_sectionMarginTop) 0 51px 0;
  background-color: rgba( 255, 255, 255, 0 )!important;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
    background-color: rgba( 255, 255, 255, 0 )!important;
  }

  @media (--viewportLarge) {
    max-width: 1440px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1440px;
    padding: 0;
  }
}

.sectionContentLearnNew {
  padding: 0 16px;

  @media (--viewportLarge) {
    max-width: 1365px;
  }

  @media (--viewport1440) {
    max-width: 1365px;
    padding: 0 36px 0 36px;
    margin-top: 90px;
  }
}

.footer {
  padding-top: 160px;
}
